import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { verifyAccessToken } from "../../compoment/jwt";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import "./Register.css";
import toast from "react-hot-toast";

const Register = () => {
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
  } = useForm();

  const navigate = useNavigate();

  const password = watch("password"); // Get the value of password to compare with repassword

  useEffect(() => {
    const token = JSON.parse(localStorage.getItem("token"));
    if (token) {
      try {
        const user = verifyAccessToken(token);

        if (user) {
          navigate("/home");
        }
      } catch (error) {
        localStorage.removeItem("token");
        console.log(error);
      }
    }
  }, [navigate]);

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/user/register`,
        data
      );
      if (response.data.success) {
        toast.success("Đăng ký thành công");
        setLoading(false);
        reset();
        navigate("/login");
      }
    } catch (e) {
      setLoading(false);
      console.log(e);
      if (e.status === 409) {
        toast.error("Email đã tồn tại");
      }
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="register-container">
      <h2>Đăng Ký</h2>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <label htmlFor="username" className="form-babel">
            Họ và tên
          </label>
          <input
            type="text"
            id="username"
            name="username"
            className="form-control"
            {...register("username", {
              required: {
                value: true,
                message: "Họ và tên không được để trống",
              },
            })}
          />
          {errors && errors.username && (
            <p className="text-danger fs-3">{errors.username.message}</p>
          )}
        </div>
        <div>
          <label htmlFor="email" className="form-babel">
            Email:
          </label>
          <input
            type="text"
            id="email"
            name="email"
            className="form-control"
            {...register("email", {
              required: {
                value: true,
                message: "Email không được để trống",
              },
              pattern: {
                value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                message: "Email không hợp lệ",
              },
            })}
          />
          {errors && errors.email && <p className="text-danger fs-3">{errors.email.message}</p>}
        </div>
        <div>
          <label htmlFor="password" className="form-babel">
            Mật khẩu:
          </label>
          <input
            type="password"
            id="password"
            name="password"
            className="form-control"
            {...register("password", {
              required: {
                value: true,
                message: "Password không được để trống",
              },
              minLength: {
                value: 6,
                message: "Mật khẩu phải có ít nhất 6 ký tự",
              },
            })}
          />
          {errors && errors.password && (
            <p className="text-danger fs-3">{errors.password.message}</p>
          )}
        </div>
        <div>
          <label htmlFor="repassword" className="form-babel">
            Nhập lại mật khẩu:
          </label>
          <input
            type="password"
            id="repassword"
            name="repassword"
            className="form-control"
            {...register("repassword", {
              required: {
                value: true,
                message: "Mật khẩu không được để trống",
              },
              minLength: {
                value: 6,
                message: "Mật khẩu phải có ít nhất 6 ký tự",
              },
              validate: (value) => value === password || "Mật khẩu nhập lại không khớp",
            })}
          />
          {errors && errors.repassword && (
            <p className="text-danger fs-3">{errors.repassword.message}</p>
          )}
        </div>
        <div>
          <button type="submit" disabled={loading}>
            Đăng Nhập
          </button>
        </div>
        <p className="to-login">
          <Link to="/login">Đăng nhập</Link>
        </p>
      </form>
    </div>
  );
};

export default Register;
