import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const BlogDetail = () => {
  const { id } = useParams(); // Lấy ID blog từ params
  const [blog, setBlog] = useState(null); // Trạng thái cho dữ liệu blog
  const [loading, setLoading] = useState(true); // Trạng thái loading
  const [error, setError] = useState(null); // Trạng thái lỗi

  useEffect(() => {
    const fetchBlog = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/blog/${id}`);
        setBlog(response.data);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchBlog();
  }, [id]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  return (
    <div style={containerStyle}>
      {/* Hero Section */}
      <div className="slider-area2">
        <div className="slider-height2 d-flex align-items-center">
          <div className="container">
            <div className="row">
              <div className="col-xl-12">
                <div className="hero-cap hero-cap2 pt-70">
                  <h2>{blog.title}</h2> {/* Tiêu đề blog động */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Blog Content */}
      <section className="blog_area single-post-area section-padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 posts-list">
              <div className="single-post">
                <div className="feature-img" style={imageContainerStyle}>
                  <img
                    className="img-fluid"
                    src={blog.img || "default-image.png"}
                    alt={blog.title}
                    style={imageStyle}
                  />
                </div>

                <div className="blog_details" style={blogDetailStyles}>
                  <h2 style={titleStyle}>{blog.title}</h2>
                  <ul className="blog-info-link mt-3 mb-4">
                    <li>
                      <a href="#">
                        <i className="fa fa-user"></i> {blog.category}
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fa fa-comments"></i> {blog.comments || 0} Comments
                      </a>
                    </li>
                  </ul>
                  {/* Excerpt */}
                  <p className="excert" style={paragraphStyle}>
                    {blog.excerpt}
                  </p>
                  {/* Blog Content */}
                  <h3 style={subTitleStyle}>{blog.title1}</h3>
                  <p style={paragraphStyle}>{blog.content1}</p>
                  {blog.img12 && <img src={blog.img12} alt={blog.title1} style={imgStyle} />}{" "}
                  {/* Hình ảnh giữa content1 và title2 */}
                  <h3 style={subTitleStyle}>{blog.title2}</h3>
                  <p style={paragraphStyle}>{blog.content2}</p>
                  {blog.img23 && <img src={blog.img23} alt={blog.title2} style={imgStyle} />}{" "}
                  {/* Hình ảnh giữa content2 và title3 */}
                  <h3 style={subTitleStyle}>{blog.title3}</h3>
                  <p style={paragraphStyle}>{blog.content3}</p>
                  {/* Quote Section */}
                  <div className="quote-wrapper" style={quoteWrapperStyles}>
                    <div className="quotes">
                      {blog.quote || "No quote available for this blog."}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Services Area */}
      <section className="services-area">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-xl-8 col-lg-8 col-md-12 col-sm-8">
              <div className="single-services mb-40">
                <div className="features-icon">
                  <img src="assets/img/icon/icon1.svg" alt="" />
                </div>
                <div className="features-caption">
                  <h3>Location</h3>
                  <p>{blog.location || "No location provided."}</p>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-8">
              <div className="single-services mb-40">
                <div className="features-icon">
                  <img src="assets/img/icon/icon2.svg" alt="" />
                </div>
                <div className="features-caption">
                  <h3>Phone</h3>
                  <p>(90) 277 278 2566</p>
                  <p>(78) 267 256 2578</p>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-8">
              <div className="single-services mb-40">
                <div className="features-icon">
                  <img src="assets/img/icon/icon3.svg" alt="" />
                </div>
                <div className="features-caption">
                  <h3>Email</h3>
                  <p>jacson767@gmail.com</p>
                  <p>contact56@zacsion.com</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

// Inline styles để giải quyết vấn đề căn chỉnh

const containerStyle = {
  padding: "0 15px",
};

const imageContainerStyle = {
  maxWidth: "900px", // Đặt chiều rộng cho khung hình
  margin: "0 auto", // Căn giữa khung hình
};

const blogDetailStyles = {
  margin: "0 auto",
  padding: "20px",
  lineHeight: "1.6",
  maxWidth: "60%",
};

const titleStyle = {
  color: "#2d2d2d",
  fontSize: "25px",
  marginBottom: "60px",
  textAlign: "center",
};

const subTitleStyle = {
  color: "#2d2d2d",
  marginTop: "20px",
  fontSize: "20px",
  marginBottom: "15px", // Thêm khoảng cách phía dưới
};

const imageStyle = {
  width: "100%",
  height: "400px",
  objectFit: "cover",
  borderRadius: "10px",
};

const imgStyle = {
  width: "100%",
  height: "auto",
  margin: "20px 0", // Khoảng cách giữa hình ảnh và nội dung
};

const paragraphStyle = {
  marginBottom: "20px",
  textAlign: "justify",
  width: "100%",
  fontSize: "18px", // Thay đổi kích thước font chữ ở đây
};

const quoteWrapperStyles = {
  padding: "20px",
  borderLeft: "4px solid #ccc",
  marginTop: "20px",
  marginBottom: "20px",
  fontStyle: "italic",
};

export default BlogDetail;
