// src/App.js
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { Toaster } from "react-hot-toast";

import "./App.css";
import BmiCalculator from "./compoment/general/BmiCalculator.jsx";
import Footer from "./compoment/general/Footer.jsx";
import Header from "./compoment/general/Header.jsx";
import About from "./page/aboutme/About";
import Blog from "./page/blog/Blog";
import BlogDetail from "./page/blog/BlogDetail";
import Contact from "./page/contact/Contact";
import Home from "./page/home/Home";
import Pricing from "./page/pricing/Pricing";

import ScrollToTop from "./compoment/ScrollToTop/ScrollToTop.js";
import Courses from "./page/courses/Courses.jsx";
import ExCategory from "./page/ExerciseCategory/ExCategory.jsx";
import VideoDetail from "./page/video/Video.jsx";
import "./translations/i18n/i18n.js";

// Thêm useLocation để lấy URL hiện tại
import { useState, useEffect } from "react";
import Chatbot from "./compoment/chatAI/Chatbot.js";
import Login from "./page/Login/Login.jsx";
import Register from "./page/Register/Register.jsx";
import { initializeGA, trackLoginEvent } from "./analytics.js";
import { verifyAccessToken } from "./compoment/jwt";
import ReactGA from "react-ga4";

function App() {
  const [location, setLocation] = useState("");

  const getLocation = (pathname) => {
    setLocation(pathname);
  };

  useEffect(() => {
    initializeGA();

    const token = localStorage.getItem("token");
    if (token) {
      const userId = verifyAccessToken(token).id; // Hàm giả định để lấy user_id từ token
      trackLoginEvent(userId);
    }

    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);

  return (
    <>
      <BrowserRouter>
        <ScrollToTop />

        {/* Hiển thị Header trừ khi đang ở trang video */}
        {!location.startsWith("/video") &&
          !location.startsWith("/exercises") &&
          !location.startsWith("/chatbot") && <Header />}

        <Routes>
          <Route path="/login" element={<Login />}></Route>
          <Route path="/register" element={<Register />}></Route>
          <Route path="/home" element={<Home />}></Route>
          <Route path="/" element={<Navigate to="/home" />}></Route>
          <Route path="/about" element={<About />}></Route>
          <Route path="/blog">
            <Route index element={<Blog />} />
            <Route path="detail/:id" element={<BlogDetail />} />
          </Route>
          <Route path="/bmi-calculator" element={<BmiCalculator />}></Route>
          <Route path="/pricing" element={<Pricing />}></Route>
          <Route path="/contact" element={<Contact />}></Route>
          <Route path="/courses" element={<Courses />}></Route>
          {/* <Route path="/chatbot" element={<Chatbot />}></Route> */}

          {/* Thêm route cho video */}
          <Route path="/exercises" element={<ExCategory getLocation={getLocation} />}></Route>
          <Route path="/video" element={<VideoDetail getLocation={getLocation} />}></Route>
          {/* <Route path="/chatbot" element={<ChatBot getLocation={getLocation} />}></Route> */}
        </Routes>

        {/* Hiển thị Footer trừ khi đang ở trang video */}
        {!location.startsWith("/video") &&
          !location.startsWith("/exercises") &&
          !location.startsWith("/chatbot") && <Footer />}
      </BrowserRouter>
      <Toaster position="top-right" reverseOrder={false} />
    </>
  );
}

export default App;
