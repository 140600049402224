import "./ExCategory.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import { verifyAccessToken } from "../../compoment/jwt";
import toast from "react-hot-toast";

const ExCategory = ({ getLocation }) => {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    const getCategory = async () => {
      try {
        const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/exercise/category`);
        setCategories(res.data);
      } catch (err) {
        console.error(err);
        setError("Không thể tải danh mục.");
      } finally {
        setLoading(false);
      }
    };
    const token = JSON.parse(localStorage.getItem("token"));
    if (!token) {
      navigate("/home");
      return;
    } else {
      const user = verifyAccessToken(token);
      if (!user) {
        navigate("/home");
        return;
      }
    }

    getCategory();
  }, []);

  const location = useLocation();

  useEffect(() => {
    getLocation(location.pathname);
    return () => {
      getLocation("");
    };
  }, [location.pathname]);

  if (loading) return <div>Đang tải danh mục...</div>;
  if (error) return <div>Lỗi: {error}</div>;

  return (
    <div className="category-selection">
      <button className="btn btn-primary btn-back btn-lg" onClick={() => navigate("/home")}>
        Back
      </button>
      <h2>Lựa chọn nhóm cơ</h2>
      <div className="category-list">
        {categories.map((category) => (
          <div key={category.id} className="category-item">
            <Link to={`/video?category=${category.name}`}>
              <img
                src={category.img || "assets/img/exercise/default.jpg"}
                alt={category.name}
                className="category-img"
                onError={(e) => (e.target.src = "assets/img/exercise/default.jpg")}
              />
              <p className="category-name">{category.name}</p> {/* Căn giữa tên */}
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ExCategory;
