import { useState } from "react";
import { useTranslation } from "react-i18next";

const BmiCalculator = () => {
  const [height, setHeight] = useState(0);
  const [weight, setWeight] = useState(0);
  const [bmi, setBmi] = useState(0);
  const [showAdvice, setShowAdvice] = useState(false);
  const { t } = useTranslation();

  const handleSubmit = (e) => {
    e.preventDefault();
    const bmiValue = weight / ((height / 100) * (height / 100));
    setBmi(bmiValue);
    setShowAdvice(true);
  };

  const getAdvice = (bmi) => {
    if (bmi < 18.5) {
      return randomAdvice(adviceMessagesTemp1);
    } else if (bmi >= 18.5 && bmi <= 24.9) {
      return randomAdvice(adviceMessagesTemp2);
    } else if (bmi >= 25 && bmi <= 29.9) {
      return randomAdvice(adviceMessagesTemp3);
    } else {
      return randomAdvice(adviceMessagesTemp4);
    }
  };

  const randomAdvice = (advices) => {
    return advices[Math.floor(Math.random() * advices.length)];
  };

  // Nhóm câu khuyên cho từng trường hợp
  const adviceMessagesTemp1 = [
    {
        remark: "Nhận xét chỉ số:\nChỉ số BMI của bạn thấp hơn mức bình thường, điều này cho thấy bạn cần tập trung vào việc tăng cân một cách lành mạnh. Thiếu cân có thể dẫn đến thiếu hụt năng lượng và dưỡng chất cần thiết để duy trì các hoạt động hàng ngày và tập luyện hiệu quả.",
        advice: "Lời khuyên cụ thể:\n- Tăng lượng calo: Bổ sung nhiều calo hơn nhu cầu hàng ngày. Đặc biệt, hãy tập trung vào các nguồn thực phẩm giàu protein (như thịt nạc, cá, trứng) và carbohydrate lành mạnh (như ngũ cốc nguyên hạt, khoai lang). Thực phẩm chứa chất béo lành mạnh như bơ, hạt, và dầu olive cũng rất tốt cho việc tăng cân.",
        exercises: "Bài tập đề xuất:\n- Bench Press: Phát triển cơ ngực và cơ tay.\n- Lat Pulldown: Phát triển cơ lưng và cải thiện sức mạnh kéo.\n- Deadlift: Tăng cường sức mạnh và khối lượng cơ bắp toàn thân."
    },
    {
        remark: "Nhận xét chỉ số:\nViệc thiếu cân có thể khiến bạn thiếu năng lượng cho các bài tập nặng và ảnh hưởng đến khả năng phát triển cơ bắp, dẫn đến hiệu suất tập luyện không được tối ưu.",
        advice: "Lời khuyên cụ thể:\n- Tập trung vào tạ nặng: Các bài tập compound như squat, deadlift, và bench press sẽ giúp bạn xây dựng cơ bắp và tăng trọng lượng cơ thể. Tăng dần mức tạ để kích thích phát triển cơ bắp.",
        exercises: "Bài tập đề xuất:\n- Bench Press: Phát triển cơ ngực và cơ tay.\n- Lat Pulldown: Phát triển cơ lưng và cải thiện sức mạnh kéo.\n- Overhead Press: Giúp phát triển cơ vai và tăng sức mạnh phần trên cơ thể."
    },
    {
        remark: "Nhận xét chỉ số:\nCơ thể bạn có thể đang thiếu một số dưỡng chất cần thiết để phát triển cơ bắp và duy trì sức khỏe tốt. Điều này có thể ảnh hưởng đến khả năng phục hồi sau tập luyện.",
        advice: "Lời khuyên cụ thể:\n- Ăn sau khi tập luyện: Sau buổi tập, cơ thể cần được cung cấp protein để phục hồi và phát triển cơ bắp. Hãy ăn một bữa có chứa ít nhất 20-30g protein ngay sau khi tập để hỗ trợ quá trình tái tạo cơ bắp.",
        exercises: "Bài tập đề xuất:\n- Bench Press: Phát triển cơ ngực và cơ tay.\n- Lat Pulldown: Phát triển cơ lưng và cải thiện sức mạnh kéo.\n- Dumbbell Lunges: Tăng sức mạnh và phát triển cơ chân."
    },
    {
        remark: "Nhận xét chỉ số:\nThiếu cân cũng có thể làm giảm sức đề kháng, khiến bạn dễ mắc bệnh hơn nếu không có kế hoạch tập luyện và dinh dưỡng phù hợp.",
        advice: "Lời khuyên cụ thể:\n- Tăng tạ dần: Bắt đầu từ mức tạ nhẹ và từ từ tăng dần trọng lượng để đảm bảo cơ bắp phát triển mà không làm quá sức hoặc gây chấn thương.",
        exercises: "Bài tập đề xuất:\n- Bench Press: Phát triển cơ ngực và cơ tay.\n- Lat Pulldown: Phát triển cơ lưng và cải thiện sức mạnh kéo.\n- Seated Row: Cải thiện sức mạnh phần lưng dưới và cơ bắp tay sau."
    },
    {
        remark: "Nhận xét chỉ số:\nBạn có cơ hội cải thiện thể trạng bằng cách kết hợp giữa dinh dưỡng tăng cường và các bài tập xây dựng cơ bắp.",
        advice: "Lời khuyên cụ thể:\n- Thời gian nghỉ ngơi: Đảm bảo ngủ đủ giấc (7-9 giờ mỗi đêm) để cơ thể có thời gian phục hồi và phát triển cơ bắp. Nghỉ ngơi hợp lý sẽ giúp cải thiện khả năng phục hồi và tăng trưởng cơ bắp nhanh chóng.",
        exercises: "Bài tập đề xuất:\n- Bench Press: Phát triển cơ ngực và cơ tay.\n- Lat Pulldown: Phát triển cơ lưng và cải thiện sức mạnh kéo.\n- Cable Crossovers: Giúp phát triển cơ ngực và tăng độ dày cho cơ."
    }
];

const adviceMessagesTemp2 = [
    {
        remark: "Nhận xét chỉ số:\nBMI của bạn nằm trong phạm vi bình thường, cho thấy bạn đang duy trì thể trạng cân đối. Điều này là dấu hiệu tốt cho thấy bạn có thể tập luyện hiệu quả và duy trì sức khỏe tối ưu.",
        advice: "Lời khuyên cụ thể:\n- Duy trì chế độ ăn: Tiếp tục ăn uống cân bằng với nhiều protein, rau củ và carbohydrate phức hợp để duy trì sức khỏe. Điều này sẽ cung cấp đầy đủ dưỡng chất cho quá trình phát triển cơ bắp và duy trì cân nặng hiện tại.",
        exercises: "Bài tập đề xuất:\n- Bench Press: Phát triển cơ ngực và cơ tay.\n- Lat Pulldown: Phát triển cơ lưng và cải thiện sức mạnh kéo.\n- Squat: Tiếp tục tập squat với tạ nặng để duy trì và phát triển cơ bắp chân."
    },
    {
        remark: "Nhận xét chỉ số:\nBạn có một tỷ lệ mỡ cơ thể lành mạnh, điều này giúp bạn duy trì sức khỏe tốt và năng lượng cho các hoạt động thể chất.",
        advice: "Lời khuyên cụ thể:\n- Thực hiện tập luyện định kỳ: Duy trì lịch tập luyện ít nhất 3-4 lần mỗi tuần, tập trung vào cả bài tập sức mạnh và cardio để cải thiện sức khỏe toàn diện.",
        exercises: "Bài tập đề xuất:\n- Bench Press: Phát triển cơ ngực và cơ tay.\n- Lat Pulldown: Phát triển cơ lưng và cải thiện sức mạnh kéo.\n- Deadlift: Tăng cường sức mạnh và khối lượng cơ bắp toàn thân."
    },
    {
        remark: "Nhận xét chỉ số:\nThể trạng của bạn tốt cho phép bạn tiếp tục tập luyện để phát triển hơn nữa về sức mạnh và cơ bắp.",
        advice: "Lời khuyên cụ thể:\n- Tăng cường sức mạnh: Nên thử thách bản thân với các bài tập mới và tăng dần mức tạ để phát triển cơ bắp tối ưu hơn.",
        exercises: "Bài tập đề xuất:\n- Bench Press: Phát triển cơ ngực và cơ tay.\n- Lat Pulldown: Phát triển cơ lưng và cải thiện sức mạnh kéo.\n- Dumbbell Rows: Giúp cải thiện sức mạnh phần lưng và cơ bắp tay."
    },
    {
        remark: "Nhận xét chỉ số:\nBạn đang ở trong tình trạng sức khỏe tốt và có thể dễ dàng đạt được mục tiêu tập luyện.",
        advice: "Lời khuyên cụ thể:\n- Chú trọng vào dinh dưỡng: Cung cấp đủ protein cho cơ bắp phục hồi sau tập luyện và tăng cường sức khỏe tổng thể.",
        exercises: "Bài tập đề xuất:\n- Bench Press: Phát triển cơ ngực và cơ tay.\n- Lat Pulldown: Phát triển cơ lưng và cải thiện sức mạnh kéo.\n- Lunges: Tăng cường sức mạnh và tính linh hoạt cho cơ chân."
    },
    {
        remark: "Nhận xét chỉ số:\nBạn có thể tận dụng thể trạng tốt để tiếp tục rèn luyện và cải thiện sức mạnh tổng thể.",
        advice: "Lời khuyên cụ thể:\n- Tích cực tham gia các hoạt động thể chất: Kết hợp giữa tập luyện sức mạnh và các hoạt động thể thao khác để duy trì sự đa dạng trong chế độ tập luyện.",
        exercises: "Bài tập đề xuất:\n- Bench Press: Phát triển cơ ngực và cơ tay.\n- Lat Pulldown: Phát triển cơ lưng và cải thiện sức mạnh kéo.\n- Plank: Tăng cường sức mạnh core và cải thiện khả năng giữ thăng bằng."
    }
];
const adviceMessagesTemp3 = [
  {
      remark: "Nhận xét chỉ số:\nBMI của bạn cao hơn mức bình thường, điều này có thể cho thấy bạn đang ở tình trạng thừa cân. Tình trạng này có thể ảnh hưởng đến sức khỏe và khả năng tập luyện của bạn.",
      advice: "Lời khuyên cụ thể:\n- Điều chỉnh chế độ ăn: Giảm lượng calo nạp vào bằng cách tránh thực phẩm chế biến sẵn và thức ăn nhanh. Hãy tăng cường rau xanh và thực phẩm giàu protein để giữ cảm giác no lâu hơn.",
      exercises: "Bài tập đề xuất:\n- Bench Press: Giúp đốt cháy calo và phát triển cơ bắp.\n- Lat Pulldown: Cải thiện sức mạnh lưng và hỗ trợ giảm cân.\n- Squat: Tăng cường sức mạnh và độ bền cho cơ chân."
  },
  {
      remark: "Nhận xét chỉ số:\nViệc thừa cân có thể gây áp lực lên các khớp xương và ảnh hưởng đến khả năng thực hiện các bài tập nặng.",
      advice: "Lời khuyên cụ thể:\n- Tập luyện đều đặn: Kết hợp giữa tập sức mạnh và cardio để giảm cân hiệu quả. Cố gắng tập ít nhất 150 phút cardio mỗi tuần.",
      exercises: "Bài tập đề xuất:\n- Bench Press: Phát triển cơ ngực và cơ tay.\n- Lat Pulldown: Phát triển cơ lưng và cải thiện sức mạnh kéo.\n- HIIT (Tập luyện cường độ cao ngắt quãng): Giúp đốt cháy calo nhanh chóng."
  },
  {
      remark: "Nhận xét chỉ số:\nMặc dù bạn đang ở tình trạng thừa cân, nhưng việc tập luyện đúng cách có thể giúp bạn cải thiện sức khỏe và thể lực.",
      advice: "Lời khuyên cụ thể:\n- Thiết lập mục tiêu thực tế: Bắt đầu từ việc giảm 0.5-1 kg mỗi tuần bằng cách điều chỉnh chế độ ăn và lịch tập luyện.",
      exercises: "Bài tập đề xuất:\n- Bench Press: Phát triển cơ ngực và cơ tay.\n- Lat Pulldown: Tăng cường sức mạnh lưng và cải thiện sức kéo.\n- Cycling: Giúp đốt cháy calo và cải thiện sức bền."
  },
  {
      remark: "Nhận xét chỉ số:\nCó một tỷ lệ mỡ cơ thể cao có thể làm tăng nguy cơ mắc các bệnh như tiểu đường và bệnh tim.",
      advice: "Lời khuyên cụ thể:\n- Tăng cường hoạt động hàng ngày: Bên cạnh việc tập luyện, hãy cố gắng hoạt động nhiều hơn trong cuộc sống hàng ngày, như đi bộ hoặc leo cầu thang.",
      exercises: "Bài tập đề xuất:\n- Bench Press: Giúp phát triển cơ bắp và đốt cháy calo.\n- Lat Pulldown: Tăng cường cơ lưng và cải thiện sức khỏe tổng thể.\n- Walking Lunges: Cải thiện sức mạnh chân và đốt cháy calo."
  },
  {
      remark: "Nhận xét chỉ số:\nBạn có thể cải thiện thể trạng của mình bằng cách kết hợp giữa chế độ ăn uống lành mạnh và tập luyện thể dục thể thao.",
      advice: "Lời khuyên cụ thể:\n- Theo dõi lượng calo: Sử dụng ứng dụng để theo dõi lượng calo bạn nạp vào và đốt cháy mỗi ngày, điều này sẽ giúp bạn có cái nhìn rõ hơn về tiến trình giảm cân.",
      exercises: "Bài tập đề xuất:\n- Bench Press: Tăng cường sức mạnh cơ ngực và tay.\n- Lat Pulldown: Phát triển cơ lưng và tăng cường sức kéo.\n- Jump Rope: Giúp đốt cháy calo và cải thiện sức bền."
  }
];

const adviceMessagesTemp4 = [
  {
      remark: "Nhận xét chỉ số:\nBMI của bạn cao hơn mức bình thường, điều này có thể cho thấy bạn đang ở tình trạng thừa cân. Tuy nhiên, bạn có cơ hội cải thiện sức khỏe bằng cách thay đổi chế độ ăn uống và tăng cường tập luyện.",
      advice: "Lời khuyên cụ thể:\n- Chế độ ăn cân bằng: Tăng cường rau củ, trái cây và thực phẩm nguyên hạt, đồng thời giảm bớt thực phẩm chứa nhiều đường và chất béo bão hòa.",
      exercises: "Bài tập đề xuất:\n- Bench Press: Tăng cường sức mạnh cơ bắp và đốt cháy calo.\n- Lat Pulldown: Cải thiện sức mạnh lưng và hỗ trợ giảm cân.\n- Cardio (Chạy bộ, đạp xe): Giúp tăng cường sức bền và giảm mỡ."
  },
  {
      remark: "Nhận xét chỉ số:\nViệc thừa cân có thể gây ảnh hưởng tiêu cực đến sức khỏe và tâm lý. Tuy nhiên, bạn hoàn toàn có thể thay đổi điều này.",
      advice: "Lời khuyên cụ thể:\n- Thiết lập mục tiêu ngắn hạn: Bắt đầu với những thay đổi nhỏ trong chế độ ăn uống và tập luyện, như giảm 250 calo mỗi ngày và tập thể dục 30 phút mỗi ngày.",
      exercises: "Bài tập đề xuất:\n- Bench Press: Tăng cường sức mạnh cho cơ ngực.\n- Lat Pulldown: Giúp phát triển cơ lưng.\n- HIIT: Tập luyện cường độ cao giúp đốt cháy calo hiệu quả."
  },
  {
      remark: "Nhận xét chỉ số:\nMặc dù bạn đang trong tình trạng thừa cân, nhưng bạn có thể cải thiện sức khỏe của mình thông qua chế độ dinh dưỡng hợp lý và tập luyện thường xuyên.",
      advice: "Lời khuyên cụ thể:\n- Theo dõi tiến trình: Ghi chép lại cân nặng, khẩu phần ăn và lịch tập luyện để dễ dàng theo dõi sự tiến bộ của bản thân.",
      exercises: "Bài tập đề xuất:\n- Bench Press: Phát triển cơ ngực và cơ tay.\n- Lat Pulldown: Cải thiện sức mạnh lưng.\n- Plank: Giúp tăng cường cơ bụng và sức mạnh tổng thể."
  },
  {
      remark: "Nhận xét chỉ số:\nViệc thừa cân có thể làm tăng nguy cơ mắc nhiều bệnh mãn tính, nhưng bạn có thể thay đổi điều này bằng cách cải thiện chế độ ăn và tập luyện.",
      advice: "Lời khuyên cụ thể:\n- Duy trì hoạt động: Cố gắng tăng cường hoạt động thể chất hàng ngày như đi bộ, đạp xe hoặc tham gia các lớp thể dục nhóm.",
      exercises: "Bài tập đề xuất:\n- Bench Press: Giúp phát triển cơ ngực và tay.\n- Lat Pulldown: Tăng cường cơ lưng và cải thiện sức khỏe.\n- Cycling: Giúp đốt cháy calo và tăng cường sức bền."
  },
  {
      remark: "Nhận xét chỉ số:\nBạn có thể cải thiện thể trạng của mình bằng cách kết hợp chế độ ăn uống lành mạnh và tập luyện thường xuyên.",
      advice: "Lời khuyên cụ thể:\n- Chú trọng đến thời gian ngủ: Ngủ đủ giấc giúp cơ thể phục hồi và hỗ trợ quá trình giảm cân hiệu quả.",
      exercises: "Bài tập đề xuất:\n- Bench Press: Tăng cường sức mạnh cho cơ ngực.\n- Lat Pulldown: Phát triển cơ lưng.\n- Squat: Giúp phát triển cơ chân và đốt cháy calo."
  }
];

  return (
    <div>
      {/* Phần tiêu đề và mô tả */}
      <div className="slider-area2">
        <div className="slider-height2 d-flex align-items-center">
          <div className="container">
            <div className="row">
              <div className="col-xl-12">
                <div className="hero-cap hero-cap2 pt-70">
                  <h2>{t("Tính Chỉ Số BMI Của Bạn")}</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="bmi-calculator-section spad">
        <div className="container">
          <div className="row">
            <div className="col-lg-5">
              <div className="section-title chart-title">
                <h2>{t("CHART.name")}</h2>
              </div>
              <div className="chart-table">
                <table>
                  <thead>
                    <tr>
                      <th>BMI</th>
                      <th>{t("CHART.weigh status")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="point">{t("CHART.Below 18.5")}</td>
                      <td>{t("CHART.Underweight")}</td>
                    </tr>
                    <tr>
                      <td className="point">18.5 - 24.9</td>
                      <td>{t("CHART.Healthy")}</td>
                    </tr>
                    <tr>
                      <td className="point">25.0 - 29.9</td>
                      <td>{t("CHART.Overweight")}</td>
                    </tr>
                    <tr>
                      <td className="point">{t("CHART.30.0 - and Above")}</td>
                      <td>{t("CHART.Obesity")}</td>
                    </tr>
                  </tbody>
                </table>
                {/* Thêm bảng rủi ro */}
                <table>
                  <thead>
                    <tr>
                      <th>{t("CHART.weigh status")}</th>
                      <th>{t("CHART.risk")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="point">{t("CHART.Underweight")}</td>
                      <td>
                        <p>{t("CHART.UnderweightRisk.one")}</p>
                        <p>{t("CHART.UnderweightRisk.two")}</p>
                        <p>{t("CHART.UnderweightRisk.three")}</p>
                        <p>{t("CHART.UnderweightRisk.four")}</p>
                        <p>{t("CHART.UnderweightRisk.five")}</p>
                        <p>{t("CHART.UnderweightRisk.six")}</p>
                      </td>
                    </tr>
                    <tr>
                      <td className="point">{t("CHART.Overweight")}</td>
                      <td>
                        <p>{t("CHART.OverweightRisk.one")}</p>
                        <p>{t("CHART.OverweightRisk.two")}</p>
                        <p>{t("CHART.OverweightRisk.three")}</p>
                        <p>{t("CHART.OverweightRisk.four")}</p>
                        <p>{t("CHART.OverweightRisk.five")}</p>
                        <p>{t("CHART.OverweightRisk.six")}</p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="col-lg-6" style={{ marginLeft: '30px' }} >
              <div className="section-title chart-calculate-title">
                <h2>{t("calculate.name")}</h2>
              </div>
              <div className="chart-calculate-form">
                <p>{t("calculate.description")}</p>
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-sm-6">
                      <input
                        type="text"
                        placeholder="Chiều cao / cm"
                        onChange={(e) => setHeight(Number(e.target.value))}
                      />
                    </div>
                    <div className="col-sm-6">
                      <input
                        type="text"
                        placeholder="Cân nặng / kg"
                        onChange={(e) => setWeight(Number(e.target.value))}
                      />
                    </div>
                    <div className="col-sm-12">
                      <button type="submit">Tính BMI</button>
                    </div>
                  </div>
                </form>
                {/* Hiển thị kết quả BMI và lời khuyên */}
                {showAdvice && (
    <div>
        <h3>{t("Chỉ số BMI của bạn là")}: {bmi.toFixed(2)}</h3>
        <p>{getAdvice(bmi).remark}</p>
        <p>{getAdvice(bmi).advice}</p>
        <p>{getAdvice(bmi).exercises}</p>
        <p>
            
        </p>
        {/* Thêm khung chat */}
        <iframe 
            src="https://sp02r5xpel13odvc1evx2.ai.copilot.live/"
            style={{ width: '100%', height: '400px', border: 'none' }}
            title="Chat Box"
        ></iframe>
    </div>
)}

              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default BmiCalculator;