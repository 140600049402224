import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const Blog = () => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/blog`);
        setPosts(response.data);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchPosts();
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error loading posts: {error.message}</div>;

  return (
    <div>
      <div className="slider-area2">
        <div className="slider-height2 d-flex align-items-center">
          <div className="container">
            <div className="row">
              <div className="col-xl-12">
                <div className="hero-cap hero-cap2 pt-70">
                  <h2 style={{ fontSize: "70px", textAlign: "center" }}>BetterWE Blog</h2>{" "}
                  {/* Căn giữa tiêu đề */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="blog_area section-padding">
        <div className="container">
          <div className="row">
            {posts.map((post, index) => (
              <div className="col-lg-6 mb-4" key={index}>
                {" "}
                {/* Chia đôi bài viết */}
                <article
                  className="blog_item"
                  style={{
                    marginBottom: "30px",
                    border: "1px solid #ddd",
                    borderRadius: "10px",
                    padding: "15px",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    transition: "transform 0.3s",
                    cursor: "pointer",
                    margin: "10px", // Thêm margin để tạo khoảng cách giữa các block
                  }}
                  onMouseEnter={(e) => (e.currentTarget.style.transform = "scale(1.03)")} // Hiệu ứng phóng to khi hover
                  onMouseLeave={(e) => (e.currentTarget.style.transform = "scale(1)")} // Trở lại kích thước ban đầu khi không hover
                >
                  <div className="blog_item_img">
                    <img
                      className="card-img rounded-0"
                      src={post.img || "default-image.png"}
                      alt={post.title || ""}
                      style={{
                        width: "100%", // Đặt chiều rộng là 100%
                        height: "250px", // Chiều cao có thể thay đổi tùy ý
                        objectFit: "cover",
                        borderRadius: "10px",
                      }}
                    />
                    <Link to="#!" className="blog_item_date">
                      <h3>{new Date(post.created_at).getDate()}</h3>
                      <p>
                        {new Date(post.created_at).toLocaleString("default", { month: "short" })}
                      </p>
                    </Link>
                  </div>
                  <div className="blog_details" style={{ textAlign: "center" }}>
                    {" "}
                    {/* Căn giữa nội dung blog */}
                    <Link to={`/blog/detail/${post.id}`}>
                      <h2 className="blog-head" style={{ color: "#2d2d2d", fontSize: "1.5rem" }}>
                        {post.title}
                      </h2>
                    </Link>
                    <ul
                      className="blog-info-link"
                      style={{ justifyContent: "center", display: "flex" }}
                    >
                      <li>
                        <Link to="#!">
                          <i className="fa fa-user"></i> {post.category_id}
                        </Link>
                      </li>
                      <li>
                        <Link to="#!">
                          <i className="fa fa-comments"></i> 0 Comments
                        </Link>
                      </li>
                    </ul>
                  </div>
                </article>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Blog;
