// src/analytics.js
import ReactGA from "react-ga4";

// Khởi tạo Google Analytics với mã đo lường
export const initializeGA = () => {
  ReactGA.initialize("G-WWRVW06RKN"); // Thay bằng mã đo lường của bạn
};

// Hàm gửi sự kiện logged_in kèm theo user_id
export const trackLoginEvent = (userId) => {
  // Thiết lập user_id trong Google Analytics
  ReactGA.set({ user_id: userId });

  // Gửi sự kiện logged_in
  ReactGA.event({
    category: "User",
    action: "logged_in",
    label: "User logged in",
  });
};
