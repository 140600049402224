import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { verifyAccessToken } from "../../compoment/jwt";
import { useNavigate, Link } from "react-router-dom";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios"; // Thêm useGoogleLogin
import "./Login.css";
import toast from "react-hot-toast";

const Login = () => {
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const navigate = useNavigate();

  useEffect(() => {
    const token = JSON.parse(localStorage.getItem("token"));
    if (token) {
      try {
        const user = verifyAccessToken(token);

        if (user) {
          navigate("/home");
        }
      } catch (error) {
        localStorage.removeItem("token");
        console.log(error);
      }
    }
  }, [navigate]);

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/user/login`, data);
      if (response.data.success) {
        const tokens = response.data.token;
        if (!tokens) {
          setLoading(false);
          return;
        }
        const accessToken = tokens;
        const user = verifyAccessToken(accessToken);
        localStorage.setItem("token", JSON.stringify(accessToken));

        setLoading(false);
        reset();
        if (user) {
          toast.success("Đăng nhập thành công");
          navigate("/home");
        }
      }
    } catch (e) {
      if (e.status === 401) {
        toast.error("Sai email hoặc mật khẩu");
      }
      setLoading(false);
    }
  };

  const googleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      try {
        setLoading(true);
        const userInfo = await axios
          .get("https://www.googleapis.com/oauth2/v3/userinfo", {
            headers: { Authorization: `Bearer ${tokenResponse.access_token}` },
          })
          .then((res) => res.data);

        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/api/user/google-login`,
          {
            email: userInfo.email,
            name: userInfo.name,
          }
        );

        const accessToken = response.data.token;
        const user = verifyAccessToken(accessToken);

        localStorage.setItem("token", JSON.stringify(accessToken));

        setLoading(false);

        if (user) {
          navigate("/home");
        }
      } catch (error) {
        setLoading(false);
        console.error("Error during Google login:", error);
      }
    },
    onError: (e) => {
      console.log(e);
    },
    scope:
      "https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/user.gender.read", // Các scope cần thiết
  });

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="login-container">
      <h2>Đăng Nhập</h2>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <label htmlFor="email" className="form-babel">
            Email:
          </label>
          <input
            type="text"
            id="username"
            name="username"
            className="form-control"
            {...register("email", {
              required: {
                value: true,
                message: "Email không được để trống",
              },
              pattern: {
                value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                message: "Email không hợp lệ",
              },
            })}
          />
          {errors && errors.email && <p className="text-danger fs-3">{errors.email.message}</p>}
        </div>
        <div>
          <label htmlFor="password" className="form-babel">
            Mật khẩu:
          </label>
          <input
            type="password"
            id="password"
            name="password"
            className="form-control"
            {...register("password", {
              required: {
                value: true,
                message: "Password không được để trống",
              },
            })}
          />
          {errors && errors.password && (
            <p className="text-danger fs-3">{errors.password.message}</p>
          )}
        </div>
        <div>
          <button type="submit" disabled={loading}>
            Đăng Nhập
          </button>
        </div>
        <p className="to-register">
          Chưa có tài khoản? <Link to="/register">Đăng ký ngay</Link>
        </p>
        <div className="login-action">
          <span>hoặc</span>
          <button
            className="google-login"
            onClick={(e) => {
              e.preventDefault();
              googleLogin();
            }}
          >
            Đăng nhập với Google
          </button>
        </div>
      </form>
    </div>
  );
};

export default Login;
